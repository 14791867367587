<template>
<div>
    <div class="title-header">
        <h1>Jobs</h1>
    </div>
    <v-container id="jobsTable" tag="section">
        <v-expansion-panels v-model="searchPanel" multiple>
            <v-expansion-panel>
                <v-expansion-panel-header>
                    <v-row no-gutters>
                        <v-col cols="10"> Advance Search </v-col>
                    </v-row>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                    <v-row>
                        <v-col cols="12" md="2" class="py-1">
                            <v-menu v-model="dateFromModal" :close-on-content-click="false" transition="scale-transition" offset-y max-width="290px" min-width="290px">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field v-model="computedDateFromFormatted" label="From" hint="DD/MM/YYYY" persistent-hint prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on" dense></v-text-field>
                                </template>
                                <v-date-picker v-model="filter.date.from" no-title @input="dateFromModal = false"></v-date-picker>
                            </v-menu>
                        </v-col>
                        <v-col cols="12" md="2" class="py-1">
                            <v-menu v-model="dateToModal" :close-on-content-click="false" transition="scale-transition" offset-y max-width="290px" min-width="290px">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field v-model="computedDateToFormatted" label="To" hint="DD/MM/YYYY" persistent-hint prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on" dense></v-text-field>
                                </template>
                                <v-date-picker v-model="filter.date.to" no-title @input="dateToModal = false"></v-date-picker>
                            </v-menu>
                        </v-col>
                        <v-col cols="12" md="2" class="py-1">
                            <v-text-field label="License" v-model="filter.carRegistration" placeholder="7กจ9920" outlined dense></v-text-field>
                        </v-col>
                        <v-col cols="12" md="2" class="py-1">
                            <v-select 
                            v-model="responseSelected"
                            :items="responsibilityList" 
                            item-text="responseName"
                            item-value="id"
                            label="Responsibility" 
                            outlined dense></v-select>
                        </v-col>
                        <v-col cols="12" md="2" class="py-1">
                            <v-select v-model="filter.branch" :items="branchList" label="Branch" outlined dense></v-select>
                        </v-col>
                        <v-col cols="12" md="2" class="py-1">
                            <v-select v-model="filter.status" :items="statusList" label="Status" outlined dense></v-select>
                        </v-col>
                    </v-row>
                    <v-row justify="end">
                        <v-col cols="12" md="3" class="py-1" :order="$vuetify.breakpoint.smAndDown ? '12' : '0'">
                            <v-btn @click="resetSearch()" block color="light" class="mt-md-2"> CLEAR </v-btn>
                        </v-col>
                        <v-col cols="12" md="3" class="py-1">
                            <v-btn @click="search()" block color="primary" class="mt-md-2"> SEARCH <v-icon dark> mdi-magnify</v-icon></v-btn>
                        </v-col>
                    </v-row>
                </v-expansion-panel-content>
            </v-expansion-panel>
        </v-expansion-panels>
        <v-row class="mt-3">
            <v-col cols="12">
                <v-btn to="job/create" color="primary"> NEW JOB </v-btn>
            </v-col>
        </v-row>
        <v-card class="mt-3" elevation="0">
            <v-card-text class="pa-0">
                <v-data-table :headers="headers" :items="allJobs" :items-per-page="10" class="custom-data-table">
                    <template v-slot:[`item.id`]="{ item }">
                        {{ item.rank }}
                    </template>

                    <template v-slot:item.jobDate="{ item }">
                        {{ item.jobDate | formatDate }}
                    </template>

                    <template v-slot:item.status="{ item }">
                        <v-chip class="text-capitalize" :color="getStatusColor(item.status)" dark>
                            {{ item.status }}<v-icon v-if="item.status == 'reject'" right>mdi-exclamation</v-icon>
                        </v-chip>
                    </template>
                    <!-- <template v-slot:item.status="{ item }">
                        {{ item.status }}
                        <v-icon :color="getStatusColor(item.status)">mdi-checkbox-blank-circle</v-icon>
                    </template> -->
                    <template v-slot:item.actions="{ item }" >
                        <div v-if="isDesktop">
                            <v-btn :to="item['status']=='draft' ? '/job/draft/' + item['id'] : '/job/edit/' + item['id']" icon>
                                <v-icon>mdi-pencil-outline</v-icon>
                            </v-btn>
                            <v-btn v-if="$can('delete','job')" color="red" icon @click="warningToDelete(item.id)">
                                <v-icon>mdi-delete-outline</v-icon>
                            </v-btn>
                        </div>
                        <div v-else>
                            <v-btn color="primary" class="mr-5" small :to="item['status']=='draft' ? '/job/draft/' + item['id'] : '/job/edit/' + item['id']" >
                                <v-icon>mdi-pencil-outline</v-icon> Edit
                            </v-btn>
                            <v-btn small color="red" @click="warningToDelete(item.id)">
                                <v-icon class="white--text">mdi-delete-outline</v-icon>
                            </v-btn>
                        </div>
                    </template>
                </v-data-table>
            </v-card-text>
        </v-card>
    </v-container>
</div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Swal from 'sweetalert2/dist/sweetalert2.js'
import 'sweetalert2/src/sweetalert2.scss'

export default {
    data() {
        return {
            allJobs: [],
            filter: {
                date:{
                        from: null
                        ,to: null
                    },
                carRegistration: null,
                jobResponsibility: null,
                branch: null,
                status: null
            },
            reponsibilityValue: {},
            responseSelected: {},
            criteriaResponsibility: {},
            isDesktop: window.innerWidth > 768 ? true : false,
            searchPanel: window.innerWidth > 768 ? [0] : [], // Input index of panel that need to defalut expand.
            statusList: ["open", "reject", "closed", "requested", "cancel", "draft"],
            branchList: ["All", "MN", "BB", "BPMN", "BPBB"],
            headers: [{
                    text: "NO.",
                    align: "center",
                    sortable: false,
                    value: "id",
                    width: 1,
                },
                {
                    text: "JOB ID",
                    value: "number",
                    width: 100,
                },
                {
                    text: "STATUS",
                    align: "center",
                    value: "status",
                    width: 120,
                },
                {
                    text: "LICENSE",
                    value: "carRegistration",
                    width: 130,
                },
                {
                    text: "DATE",
                    value: "jobDate",
                    width: 180,
                },
                {
                    text: "RESPONSIBILITY",
                    value: "responsibilityName",
                     width: 190,
                },
                {
                    text: "ACTIONS",
                    value: "actions",
                    sortable: false,
                    width: 110,
                    align: "center"
                },
            ],
            criteriaStatus: null,
            criteriaDateFrom: new Date().toISOString().substr(0, 10),
            criteriaDateTo: new Date().toISOString().substr(0, 10),
            dateFormatted: this.formatDate(new Date().toISOString().substr(0, 10)),
            dateFromModal: false,
            dateToModal: false
        };
    },
    computed: {
        ...mapGetters({
            jobsTable: 'job/GETTER_JOBS',
            responsibilityList: 'job/GETTER_REPONSIBILITY',
            keepFilter: 'job/GETTER_KEEP_FILTER',
            searchFilter: 'job/GETTER_SEARCH_FILTER'
        }),
        computedDateFromFormatted() {
            return this.formatDate(this.filter.date.from);
        },
        computedDateToFormatted() {
            return this.formatDate(this.filter.date.to);
        }
    },
    watch: {
        async jobsTable(val){
            val = await this.sortTable(val);
            this.allJobs = this.rankedItems(val);
        },
        criteriaDate() {
            this.dateFormatted = this.formatDate(this.criteriaDate);
        },
        responseSelected(value) {
            if (typeof value === 'object' && value !== null){
                this.filter.jobResponsibility = value.id
            }else{
                this.filter.jobResponsibility = value
            }
        },
    },
    async created() {    
        await this.getJobs();
        await this.getResponsibility();

        this.filter = this.keepFilter;
        if(this.searchFilter) {
            this.responseSelected = this.filter.jobResponsibility
            await this.search()
        }
    },
    async mounted() {
        this.filter = this.keepFilter;
        if(this.searchFilter) {
            this.responseSelected = this.filter.jobResponsibility
            await this.search()
        }
    },
    methods: {
        ...mapActions({
            getJobs: 'job/GET_JOBS',
            updateJobs: 'job/UPDATE_JOBS',
            deleteJob: 'job/DELETE_JOB',
            getResponsibility: 'job/GET_RESPONSIBILITY',
            updateKeepFilter: 'job/UPDATE_KEEP_FILTER',
            updateSearchFilter: 'job/UPDATE_SEARCH_FILTER'
        }),
        sortTable(values) {
            if(this.$can('sortSpecial','jobIndex')) {
                const sortBy = ['draft', 'reject', 'open', 'requested', 'closed', 'cancel'];
                values = values.map(job => {
                    const sortRank = sortBy.findIndex(s => s === job.status);
                    job.rank = sortRank
                    return job
                })
                values = values.sort((a, b) => a.rank - b.rank);
            } else {
                const sortBy = ['requested', 'draft', 'reject', 'open', 'closed', 'cancel'];
                values = values.map(job => {
                    const sortRank = sortBy.findIndex(s => s === job.status);
                    job.rank = sortRank
                    return job
                })
                values = values.sort((a, b) => a.rank - b.rank);
            }
            return values;
        },
        formatDate(date) {
            if (!date) return null;
            const [year, month, day] = date.split("-");
            return `${day}/${month}/${year}`;
        },
        getStatusColor(status) {
            if (status == "open") return "red";
            else if (status == "requested") return "amber darken-2";
            else if (status == "closed") return "green";
            else if (status == "cancel") return "grey lighten-1";
            else if (status == "reject") return "red darken-4";
            else return ""; // Default color: gray
        },
        warningToDelete(id) {
            Swal.fire({
                title: 'Are you sure?',
                text: "You won't be able to revert this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#EF5050',
                cancelButtonColor: '#C6C6C6',
                confirmButtonText: 'Delete'
                }).then((result) => {
                if (result.isConfirmed) {
                    this.deleteJob(id);
                    Swal.fire(
                        'Deleted!',
                        `Your job id: ${id} has been deleted.`,
                        'success'
                    )
                }
            })
        },
        rankedItems(data) {
            const items = [];
            if (data.length > 0) {
                items[0] = data[0];
                items[0].rank = 1;
                for (let index = 1; index < data.length; index++) {
                items[index] = data[index];
                items[index].rank = index + 1;
                }
            }
            return items;
        },
        // escapeRegExp(stringToGoIntoTheRegex) {
        //     return stringToGoIntoTheRegex.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&');
        // },
        search(){

            this.updateKeepFilter(this.filter)
            this.updateSearchFilter(true)

            let items = this.jobsTable;
            const { date, carRegistration, jobResponsibility, branch, status } = this.filter;
            const dateEmpty = !Object.values(date).every(x => (x !== null && x !== ''));
            
            if(!dateEmpty){
                items = items.filter(entry => {
                    let jobDate = new Date(entry.jobDate);jobDate.setHours(0,0,0,0);
                    let startDate = new Date(date.from);startDate.setHours(0,0,0,0);
                    let endDate = new Date(date.to);endDate.setHours(0,0,0,0);
                    return jobDate.getTime() >= startDate.getTime() && jobDate.getTime() <= endDate.getTime() 
                });
            }

            if(carRegistration){
                items = items.filter(entry => {
                    let string = entry.carRegistration.toLowerCase();
                    let regex = new RegExp(`^.*${carRegistration.toLowerCase()}.*$`);
                    return string.match(regex)
                });
            }

            if(jobResponsibility){
                items = items.filter(entry => entry.jobResponsibility == jobResponsibility);
            }

            if(branch && branch != "All"){
                items = items.filter(entry => entry.number.startsWith(branch, 0));
            }

            if(status){
                items = items.filter(entry => entry.status == status.toLowerCase());
            }

            this.allJobs = this.rankedItems(items);
        },
        resetSearch(){
            this.filter = {
                date:{
                        from: null
                        ,to: null
                    },
                carRegistration: null,
                jobResponsibility: null,
                branch: null,
                status: null
            }
            this.responseSelected = {};
            this.updateJobs(this.sortTable(this.jobsTable));
            this.allJobs = this.rankedItems(this.jobsTable);
            this.updateKeepFilter(this.filter)
            this.updateSearchFilter(false)
        }
    },
};
</script>

<style lang="scss" scoped>

</style>
