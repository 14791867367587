<template>
    <div>
        <v-btn block color="primary" @click="exportPDF()" class="mt-md-2"> Print PDF </v-btn>
    </div>
</template>

<script>
import pdfMake from 'pdfmake'
import pdfFonts from '@/assets/custom-fonts.js'

export default {
    methods: {
        exportPDF() {
            const totalTitleEl = document.getElementById('total-title').innerHTML
            const totalJobEl = document.getElementById('total-jobs')
            const jobStatusEl = document.getElementById('job-status')
            const complaintsEl = document.getElementById('complaints')
            const complaintsSaEl = document.getElementById('complaints-sa')

            const totalJobBase64 = totalJobEl.toDataURL()
            const jobStatusBase64 = jobStatusEl.toDataURL()
            const complaintsBase64 = complaintsEl.toDataURL()
            const complaintsSaBase64 = complaintsSaEl.toDataURL()

            pdfMake.vfs = pdfFonts.pdfMake.vfs
            pdfMake.fonts = {
                Kanit: {
                    normal: 'Kanit-Regular.ttf',
                    bold: 'Kanit-Medium.ttf',
                    italics: 'Kanit-Italic.ttf',
                    bolditalics: 'Kanit-MediumItalic.ttf'          
                }
            }
            const docDefinition = {
                pageSize: 'A4',
                pageOrientation: 'landscape',
                // [left, top, right, bottom] or [horizontal, vertical] or just a number for equal margins
                pageMargins: [ 40, 60, 40, 40 ],
                info: {
                    title: 'SA Report',
                },
                content: [
                    { text: totalTitleEl, style: 'header' },
                    {
                        image: totalJobBase64,
                        width: 800,
                        style: 'graphContentTotal',
                    },
                    {
                        columns: [
                            [
                                { text: 'Job Status', style: 'header' },
                                {
                                    image: jobStatusBase64,
                                    width: 250,
                                    style: 'graphContent',
                                }
                            ],
                            [
                                { text: 'Complaints', style: 'header' },
                                {
                                    image: complaintsBase64,
                                    width: 250,
                                    style: 'graphContent',
                                }
                            ],
                            [
                                { text: 'SA', style: 'header' },
                                {
                                    image: complaintsSaBase64,
                                    width: 250,
                                    style: 'graphContent',
                                }
                            ]
                        ],
                    },
                ],
                styles: {
                    header: {
                        fontSize: 10,
                        bold: true,
                        alignment: 'center',
                        margin: [0,0,0,10]
                    },
                    graphContentTotal: {
                        alignment: 'center',
                        margin: [0,0,0,30]
                    },
                    graphContent: {
                        alignment: 'center',
                        margin: [0,0,0,0]
                    }
                },
                defaultStyle: {
                    font: 'Kanit'
                }
            }
            pdfMake.createPdf(docDefinition).open()
        }
    },
}
</script>