<template>
    <div>
        <div class="title-header">
            <h1>Reports</h1>
        </div>
        <v-container tag="section">
            <v-expansion-panels v-model="searchPanel" multiple>
            <v-expansion-panel>
                <v-expansion-panel-header>
                    <v-row no-gutters>
                        <v-col cols="10"> Report Filter </v-col>
                    </v-row>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                    <v-row>
                        <v-col cols="12" md="3" class="py-1">
                            <v-menu v-model="dateFromModal" :close-on-content-click="false" transition="scale-transition" offset-y max-width="290px" min-width="290px">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field v-model="computedDateFromFormatted" label="Date From" hint="DD/MM/YYYY" persistent-hint prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on" dense></v-text-field>
                                </template>
                                <v-date-picker v-model="criteriaDateFrom" no-title @input="dateFromModal = false"></v-date-picker>
                            </v-menu>
                        </v-col>
                        <v-col cols="12" md="3" class="py-1">
                            <v-menu v-model="dateToModal" :close-on-content-click="false" transition="scale-transition" offset-y max-width="290px" min-width="290px">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field v-model="computedDateToFormatted" label="Date To" hint="DD/MM/YYYY" persistent-hint prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on" dense></v-text-field>
                                </template>
                                <v-date-picker v-model="criteriaDateTo" no-title @input="dateToModal = false"></v-date-picker>
                            </v-menu>
                        </v-col>
                        <v-col cols="12" md="3" class="py-1">
                            <v-select 
                            v-model="responseSelected" 
                            :items="responsibilityList" 
                            item-text="responseName"
                            item-value="id"
                            label="Responsibility" 
                            outlined dense></v-select>
                        </v-col>
                        <v-col cols="12" md="3" class="py-1">
                            <v-select v-model="criteriaBranch" :items="branchList" @change="search()" label="Branch" outlined dense></v-select>
                        </v-col>
                    </v-row>
                    <v-row justify="end">
                        <v-col cols="12" md="2" class="py-1">
                            <v-btn block color="primary" @click="search()" class="mt-md-2"> search </v-btn>
                        </v-col>
                        <v-col cols="12" md="2" class="py-1">
                            <PDFReport />
                        </v-col>
                        <!-- <v-col cols="12" md="2" class="py-1">
                            <v-btn block color="primary" @click="searchAllResponsibility()" class="mt-md-2"> search all</v-btn>
                        </v-col> -->
                    </v-row>
                </v-expansion-panel-content>
            </v-expansion-panel>
        </v-expansion-panels>
            <v-row>
                <v-col cols="12" md="12" class="py-1">
                    <div class="Chart">
                        <!-- <h1 id="total-title" style="text-align:center;">Total Jobs ({{criteriaBranch == 'All' ? criteriaBranch : criteriaBranch == 'MN' ? 'สำนักงานใหญ่' : 'สาขาบ้านบึง'}})</h1> -->
                        <h1 v-if="criteriaBranch === 'MN'" id="total-title" style="text-align:center;padding-top: 15px;">Total Jobs (สำนักงานใหญ่)</h1>
                        <h1 v-else-if="criteriaBranch === 'BB'" id="total-title" style="text-align:center;padding-top: 15px;">Total Jobs (สาขาบ้านบึง)</h1>
                        <h1 v-else-if="criteriaBranch === 'BPMN'" id="total-title" style="text-align:center;padding-top: 15px;">Total Jobs (อู่สีสำนักงานใหญ่)</h1>
                        <h1 v-else-if="criteriaBranch === 'BPBB'" id="total-title" style="text-align:center;padding-top: 15px;">Total Jobs (อู่สีบ้านบึง)</h1>
                        <h1 v-else id="total-title" style="text-align:center;padding-top: 15px;">Total Jobs ({{criteriaBranch}})</h1>
                        <bar-chart chartId="total-jobs" />
                    </div>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12" md="4" class="py-1">
                    <div class="Chart">
                        <h1 style="text-align:center;">Job Status</h1>
                        <pie-chart chartId="job-status" />
                    </div>
                </v-col>
                <v-col cols="12" md="4" class="py-1">
                    <div class="Chart">
                        <h1 style="text-align:center;">Complaints</h1>
                        <pie-chart-complaint chartId="complaints"/>
                    </div>
                </v-col>
                <v-col cols="12" md="4" class="py-1">
                    <div class="Chart">
                        <h1 style="text-align:center;">SA</h1>
                        <pie-chart-complaint-sa chartId="complaints-sa"/>
                    </div>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import BarChart from '@/components/base/BarChart.vue';
import PieChart from '@/components/base/PieChart.vue';
import PieChartComplaint from '@/components/base/PieChartComplaint.vue';
import PieChartComplaintSa from '@/components/base/PieChartComplaintSa.vue';
import PDFReport from '@/components/PDFReport.vue';

export default {
    components: {
        BarChart,
        PieChart,
        PieChartComplaint,
        PieChartComplaintSa,
        PDFReport
    },
    data() {
        return {
            reponsibilityValue: {},
            responseSelected: {},
            searchPanel: window.innerWidth > 768 ? [0] : [],
            loaded: false,
            downloads: [{day: '2017–03–20', downloads: '3'},
            {day: '2017–03–21', downloads: '2'},
            {day: '2017–03–22', downloads: '10'},],
            labels: [],
            criteriaDateFrom: `${new Date().getFullYear()}-${new Date().getMonth()+1}-01`,
            dateFromFormatted: this.formatDate(new Date().toISOString().substr(0, 10)),
            dateFromModal: false,
            criteriaDateTo: `${new Date().getFullYear()}-${new Date().getMonth()+1}-${new Date(new Date().getFullYear(), (new Date().getMonth()+1), 0).getDate()}`,
            dateToFormatted: this.formatDate(new Date().toISOString().substr(0, 10)),
            dateToModal: false,
            criteriaResponsibility: "",
            branchList: ["All", "MN", "BB", "BPMN", "BPBB"],
            criteriaBranch: "All"
        };
    },
    computed: {
        ...mapGetters({
            responsibilityList: 'job/GETTER_REPONSIBILITY_SEARCH',
            // jobsTable: 'job/getJobTable'
        }),
        computedDateFromFormatted() {
            return this.formatDate(this.criteriaDateFrom);
        },
        computedDateToFormatted() {
            return this.formatDate(this.criteriaDateTo);
        },
    },
    watch: {
        criteriaDateFrom() {
            this.dateFromFormatted = this.formatDate(this.criteriaDateFrom);
        },
        criteriaDateTo() {
            this.dateToFormatted = this.formatDate(this.criteriaDateTo);
        },
        responseSelected(value) {

            if (typeof value === 'object' && value !== null){
                this.reponsibilityValue = value.id
            }else{
                this.reponsibilityValue = value
            }
            
            this.criteriaResponsibility = this.reponsibilityValue
        },
    },
    async created() {
        await this.getResponsibility();
        await this.setDefaultSelected();
    },
    methods: {
        ...mapActions({
            getResponsibility: 'job/GET_RESPONSIBILITY_SEARCH',
            updateReport: 'job/UPDATE_REPORT'
        }),
        setDefaultSelected() {
            let me = JSON.parse(localStorage.getItem('user'));
            let index = this.responsibilityList.findIndex(obj => obj.id === `${me.id}` );
            this.responseSelected = index != -1 ? this.responsibilityList[index] : this.responsibilityList[0]
        },
        search(){
            this.updateReport({start: this.criteriaDateFrom, end: this.criteriaDateTo, responsibility: this.criteriaResponsibility, branch: this.criteriaBranch});
        },
        // searchAllResponsibility(){
        //     this.updateReport({start: this.criteriaDateFrom, end: this.criteriaDateTo, responsibility: null});
        // },
        formatDate(date) {
            if (!date) return null;
            const [year, month, day] = date.split("-");
            return `${day}/${month}/${year}`;
        },
    },
};
</script>

<style lang="scss" scoped>

</style>
